<div class="page">
    <main>
        <form class="form"
              [formGroup]="loginForm"
        >
            <h2>Login</h2>

            <div class="tui-form__row">
                <tui-input formControlName="login">Username<span class="tui-required"></span></tui-input>
                <tui-error [error]="computedFormError('login')"></tui-error>
            </div>

            <div class="tui-form__row">
                <tui-input-password formControlName="password">Password<span class="tui-required"></span></tui-input-password>
                <tui-error [error]="computedFormError('password')"></tui-error>
            </div>

            <div class="tui-form__row">
                <tui-error [error]="computedFormError(null)"></tui-error>
            </div>

            <div class="tui-form__buttons tui-form__buttons_align_end">
                <button
                        tuiButton class="tui-form__button"
                        size="l"
                        type="submit"
                        (click)="submit()"
                        [showLoader]="isLoading"
                >Login</button>
            </div>
        </form>
    </main>
</div>

import {Component, EventEmitter, Input, Output, SimpleChanges} from '@angular/core';
import {NavigationRoutes} from "../navigation/navigation-route";
import {UserService} from "../../user/user.service";


@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent {
  isHideMenu = false;

  routes: NavigationRoutes = [];

  allRoutes: NavigationRoutes = [
    {
      label: 'Dashboard',
      open: false,
      commands: ['/dashboard'],
      acl: "dashboard__view",
      icon: 'tuiIconUsersLarge'
    },
    {
      label: 'Benutzer',
      open: false,
      commands: ['/users'],
      icon: 'tuiIconUsersLarge'
    },
    // {
    //   label: 'Studenten',
    //   open: false,
    //   commands: ['/students'],
    //   // children: [
    //   // ],
    // },
    {
      label: 'Tickets',
      open: false,
      commands: ['/tickets'],
      // children: [
      // ],
    },
  ];

  isLoading = false;

  constructor(
      public readonly userService: UserService,
  ) {
  }

  ngOnInit(): void {

    // this.auth.onAuth$.pipe(
    //     filter(({state}) => state !== AuthState.PENDING),
    //     distinctUntilChanged(),
    // ).subscribe(res => {
    //   this.reloadMenu(res.user.permissions)
      this.reloadMenu([])
      this.isLoading = false;
    // });

  }

  toggleMenuHiding(isHidden: boolean) {
    this.isHideMenu = isHidden;
  }

  reloadMenu(permissions: any[]) {
    let keys = permissions.map(p => p.key)
    this.routes = [];
    for (let r of this.allRoutes) {
      // if (r.acl) {
      //     if (keys.indexOf(r.acl) < 0) {
      //       continue;
      //     }
      // }
      this.routes.push(r)
    }
  }
}

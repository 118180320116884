import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {AuthState, UserService} from "./user.service";


@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    public loginPageUri = ['/user/login'];

    constructor(
        private router: Router,
        private auth: UserService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, routerState: RouterStateSnapshot): Observable<boolean | UrlTree> {
        return this.auth.getActualState().pipe(
            map(state => {
                if (this.auth.user) {
                    this.router.navigate(['/dashboard']);
                }
                return state === AuthState.LOGGED_IN ? true : this.router.createUrlTree(this.loginPageUri);
            }),
        );
    }

}

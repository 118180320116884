import {DateRangeFilter, PageInfo} from "../shared.types";
import {TuiDay} from "@taiga-ui/cdk";


export class UserState {
    id: string

    name: string

    constructor(id: string, name: string) {
        this.id = id;
        this.name = name;
    }
}

export let ALL_STATES: string[] = [
    'Ja',
    'Nein',
];

export class UserTitle {
    id: string

    name: string

    constructor(id: string, name: string) {
        this.id = id;
        this.name = name;
    }
}

export let ALL_TITLES: string[] = [
    'Männlich',
    'Weiblich',
    'Divers',
];

export class User {
    id: number | null;
    title: string | null;
    username: string | null;
    name: string | null;
    email: string | null;
    firstName: string | null;
    birthday: Date | null;
    newPassword?: string | null;
    dateCreated: Date | null;
    lastActivity: Date | null;
    location: string | null;
    room: string | null;
    phone: string | null;
    state: string | null;
    competence: string | null;
    permissions: string[] | null;

    constructor(
        id: number | null = null, name: string | null = null,
        title: string | null = null, username: string | null = null,
        email: string | null = null, firstName: string | null = null,
        dateCreated: Date | null = null, lastActivity: Date | null = null,
        birthday: Date | null = null, location: string | null = null,
        room: string | null = null, phone: string | null = null,
        state: string | null = null, competence: string | null = null,
        permissions: string[] | null = null) {
        this.id = id;
        this.name = name;
        this.title = title;
        this.username = username;
        this.email = email;
        this.firstName = firstName;
        this.lastActivity = lastActivity;
        this.dateCreated = dateCreated;
        this.birthday = birthday;
        this.location = location;
        this.room = room;
        this.phone = phone;
        this.state = state;
        this.competence = competence;
        this.permissions = permissions;
    }

    getFullname() {
        return this.title + ' ' + this.name + ' ' + this.firstName;
    }
}

export interface UserListParams {
    search?: string | null;
    birthday?: DateRangeFilter;
    dateCreated?: DateRangeFilter;
    lastActivity?: DateRangeFilter;
    state?: string;
    limit: number;
    offset: number;
}

export interface UserListResponse {
    list: User[]
    pageInfo: PageInfo
}
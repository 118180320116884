import {Component, OnInit} from '@angular/core';
import {AuthStateObject, UserService} from "../../../user/user.service";

@Component({
  selector: 'app-header-auth',
  templateUrl: './header-auth.component.html',
  styleUrls: ['./header-auth.component.scss']
})
export class HeaderAuthComponent implements OnInit {
  authUserState?: AuthStateObject
  constructor(
      public readonly userService: UserService
  ) {
  }

  ngOnInit(): void {
    this.userService.onAuth$.subscribe( state => this.authUserState = state );
  }
}

import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {NavigationRoutes} from './navigation-route';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit, OnChanges {
  @Input() routes: NavigationRoutes = [];
  @Output() toggleMenuHiding: EventEmitter<boolean> = new EventEmitter<boolean>();

  isHideMenu = false;
  filteredRoutes: NavigationRoutes = [];

  constructor() {
  }

  ngOnInit(): void {
    // this.acl.permissions$.pipe(
    //     takeUntil(this.destroy$),
    // ).subscribe(() => this.prepareRoutes());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('routes' in changes) this.prepareRoutes();
  }

  private prepareRoutes() {
    this.filteredRoutes = this.routes
        // .filter(group => this.acl.has(group.acl))
        .map(group => {
          group.commands = group.commands || [];
          group.open = group.open || false;
          return group;
        });
  }

  onToggleHideNavigation() {
    this.isHideMenu = !this.isHideMenu;

    this.toggleMenuHiding.emit(this.isHideMenu);
  }
}

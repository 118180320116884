import {Component, OnInit} from '@angular/core';
import {AuthState, AuthStateObject, UserService} from "../user.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {TuiValidationError} from "@taiga-ui/cdk";
import {Router} from "@angular/router";

@Component({
  selector: 'app-user-login',
  templateUrl: './user-login.component.html',
  styleUrls: ['./user-login.component.scss']
})
export class UserLoginComponent implements OnInit {

    readonly loginForm = new FormGroup({
            login: new FormControl<string>('', [Validators.required]),
        password: new FormControl<string>('', [Validators.required]),
        }
    );

    authUserState?: AuthStateObject

    isLoading = false;

    loginFailed = false

    constructor(
        private readonly router: Router,
        public readonly userService: UserService
    ) {
    }

    ngOnInit(): void {
        this.userService.onAuth$.subscribe( res => {
            if (res.state === AuthState.LOGGED_IN) {
                this.router.navigate(['/dashboard']);
            }
            this.authUserState = res;
        } );
    }

    computedFormError(fieldName: string | null): TuiValidationError | null {
        if (!fieldName) {
            if (this.loginFailed) {
                return new TuiValidationError('Login order das Passwort ist ungültig.');
            }
            return null;
        }
        const field = this.loginForm.get(fieldName);

        if (!field || !field?.touched || field?.valid) {
            return null;
        }

        return new TuiValidationError('Ist pflichtfeld.');
    }

    submit() {
        this.isLoading = true;
        this.userService.logIn(
            this.loginForm.value.login ? this.loginForm.value.login : '',
            this.loginForm.value.password ? this.loginForm.value.password : ''
        ).subscribe((res) => {
            this.isLoading = false;
            this.loginFailed = res.state != AuthState.LOGGED_IN;
        })
    }
}

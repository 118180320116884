<nav class="navigation">
    <div class="navigation__toggler" (click)="onToggleHideNavigation()" *ngIf="false">
        <button tuiButton size="m" class="navigation__toggler-button">
            <tui-svg [class.navigation_hide]="isHideMenu" src="tuiIconArrowLeftLarge"></tui-svg>
        </button>
        <span class="navigation__hide-text"
              [class.navigation_hide-menu]="isHideMenu"
        >Hauptmenü
    </span>
    </div>
    <tui-scrollbar [class.navigation_hide-menu]="isHideMenu">
        <tui-accordion [rounded]="false" [closeOthers]="false">
            <tui-accordion-item
                    [class.no-children]="!group.children"
                    *ngFor="let group of routes"
                    [showArrow]="true"
                    [borders]="null"
                    size="s"

                    [disabled]="!group.children"
            >
                <strong
                        *ngIf="group.children"
                        class="title">{{group.label}}
                </strong>

                <strong
                        *ngIf="!group.children"
                        [routerLink]="group.commands"
                        routerLinkActive="link_active"
                        class="title no-children">{{group.label}}
                </strong>

                <ng-template tuiAccordionItemContent style="padding: 1rem;">
                    <div class="section">
                        <a
                                tuiLink
                                class="link"
                                routerLinkActive="link_active"
                                *ngFor="let link of group.children"
                                [routerLink]="link.commands"
                        >
                            {{link.label}} <span class="red" *ngIf="link.amount">({{link.amount}})</span>
                        </a>
                    </div>
                </ng-template>
            </tui-accordion-item>
        </tui-accordion>
    </tui-scrollbar>
</nav>



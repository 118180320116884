import {Inject, Injectable, Injector} from '@angular/core';
import {Resolve} from '@angular/router';
import {Observable, of, OperatorFunction, pipe, ReplaySubject, throwError} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, map, switchMap, take, tap} from 'rxjs/operators';

import {User, UserListParams} from './user';
import {TuiAlertService, TuiDialogService} from '@taiga-ui/core';
import {ApiService} from "../services/api.service";

export enum AuthState {
    PENDING = 'pending',
    LOGGED_IN = 'logged-in',
    LOGGED_OUT = 'logged-out',
}


export class AuthStateObject {
    state: AuthState = AuthState.PENDING;
    user?: User;
}

@Injectable({
    providedIn: 'root',
})
export class UserService {

    readonly #onAuth$ = new ReplaySubject<AuthStateObject>(1);

    public onAuth$ = this.#onAuth$.pipe(
        debounceTime(0),
        distinctUntilChanged(),
    );

    public user?: User = undefined;

    public user$ = this.onAuth$.pipe(
        filter(state => state.state !== AuthState.PENDING),
        map(state => state.user),
    );

    public getActualState(): Observable<AuthState> {
        return this.#onAuth$.pipe(
            // tap(state => {
            //     console.log('FFFFF', state)
            // }),
            filter(({state}) => state !== AuthState.PENDING),
            take(1),
            map(({state}) => state),
        );
    }


    constructor(
        @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
        @Inject(Injector) private readonly injector: Injector,
        protected apiService: ApiService,
        @Inject(TuiAlertService)
        private readonly alerts: TuiAlertService,
    ) {
        requestAnimationFrame(() => this.initCurrentUser());
    }

    initCurrentUser() {
        this.apiService.post(
            'user/current',
            { }
        ).subscribe(res => {
            // this.alerts.open(
            //     `User data loaded for: ${res?.username}`,
            //     { status:"info" }
            // ).subscribe();
            if (res) {
                console.log(res)
                this.#onAuth$.next({
                    user: res.user,
                    state: res.isAuthenticated ? AuthState.LOGGED_IN : AuthState.LOGGED_OUT
                });
            }
        });
    }

    getUser(id: number): Observable<User> {
        return this.apiService.post(
            'user/get',
            { id }
        ).pipe(
            map((res: any) =>  {
                res = this.getUserFromResponse(res.get);
                return res
            })
        )
    }

    getUserList(filter: UserListParams): Observable<User[]> {
        return this.apiService.post(
            'user/list',
            filter
        ).pipe(
            map((res: any) =>  {
                res.list = res.list.map((u: any) => this.getUserFromResponse(u))
                return res
            })
        )
    }

    getUserFromResponse(res: any): User {
        let user = new User(
            res.id || null,
            res.name || null,
            res.title || null,
            res.username || null,
            res.email || null,
            res.firstName || null,
            res.dateCreated ? new Date(res.dateCreated) : null,
            res.lastActivity ? new Date(res.lastActivity) : null,
            res.birthday ? new Date(res.birthday) : null,
            res.location || null,
            res.room || null,
            res.phone || null,
            res.isEnabled === '1' ? 'ja' : 'nein',
            res.competence || null,
            res.permissions || null,
        );

        return user
    }

    saveUser(user: User): Observable<User> {
        return this.apiService.post(
            'user/save',
            { user: user }
        ).pipe(
            map((res:any) =>  this.getUserFromResponse(res.save))
        )
    }

    deleteUser(id: number): Observable<Boolean> {
        return of(true)
    }

    public logIn(login: string, password: string): Observable<{
        state: AuthState,
        user: User,
    }> {
        return this.apiService.post(
            'user/login',
            { login, password }
        ).pipe(
            map(res =>  {
                const out = {
                    user: res.user,
                    state: res.isLoggedIn ? AuthState.LOGGED_IN : AuthState.LOGGED_OUT
                };
                if (res.isLoggedIn) {
                    localStorage.setItem('token', res.token);
                    this.#onAuth$.next(out);
                } else {
                    localStorage.removeItem('token');
                }
                return out
            })
        )
    }

    // public logOut(): Observable<AuthStateObject> {
    //     return this.logOutGQL.mutate().pipe(
    //         map(response => response.data.result?.logout.token),
    //         this.handleToken(),
    //     );
    // }



    //
    // private storeUser(user: User) {
    //     if (!user) return this.storage.removeItem('auth');
    //     const string = JSON.stringify(user);
    //     const codeUnits = new Uint16Array(string.length);
    //     for (let i = 0; i < codeUnits.length; i++) {
    //         codeUnits[i] = string.charCodeAt(i);
    //     }
    //     const data = btoa(String.fromCharCode(...new Uint8Array(codeUnits.buffer)));
    //     this.storage.setItem('auth', data);
    // }
    //
    // private restoreUser(): User | undefined {
    //     try {
    //         const data = this.storage.getItem('auth');
    //         const binary = atob(data);
    //         const bytes = new Uint8Array(binary.length);
    //         for (let i = 0; i < bytes.length; i++) {
    //             bytes[i] = binary.charCodeAt(i);
    //         }
    //         const string = String.fromCharCode(...new Uint16Array(bytes.buffer));
    //         this.user = JSON.parse(string);
    //     } catch (e) {
    //         this.user = undefined;
    //     }
    //     return this.user;
    // }

    // private handleToken(): OperatorFunction<string, AuthStateObject> {
    //     return pipe(
    //         tap(token => token ? this.token.set(token) : this.token.remove()),
    //         switchMap(() => this.fetchUser()),
    //         switchMap(() => this.onAuth$),
    //         take(1),
    //     );
    // }

    login() {

    }
}

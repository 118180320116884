import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './layout/layout.component';
import { RouterModule } from "@angular/router";
import { NavigationComponent } from './navigation/navigation.component';
import {TuiLoaderModule, TuiScrollbarModule, TuiSvgModule} from "@taiga-ui/core";
import {TuiAccordionModule} from "@taiga-ui/kit";
import { HeaderComponent } from './header/header.component';
import { HeaderAuthComponent } from './header/header-auth/header-auth.component';



@NgModule({
  declarations: [
    LayoutComponent,
    NavigationComponent,
    HeaderComponent,
    HeaderAuthComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    TuiSvgModule,
    TuiScrollbarModule,
    TuiAccordionModule,
    TuiLoaderModule
  ]
})
export class LayoutModule { }

import {Inject, Injectable} from '@angular/core';
import {catchError, delay, Observable, retry, throwError} from 'rxjs';


import {HttpClient, HttpHeaders} from "@angular/common/http";
import {TuiAlertService} from "@taiga-ui/core";
import {debounceTime} from "rxjs/operators";



@Injectable({
    providedIn: 'root',
})
export class ApiService {
    apiPfx = '/api/'
    constructor(
        private http: HttpClient,
        @Inject(TuiAlertService)
        private readonly alerts: TuiAlertService,
        ) {}

    getHttpOptions() {
        const token = localStorage.getItem('token');
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': token ? 'JWT ' + token : ''
        });
        return {
            headers
        }
    }

    post(url: string, postData: any): Observable<any> {
        return this.http
            .post<any>(
                 this.apiPfx + url,
                JSON.stringify(postData),
                this.getHttpOptions()
            )
            .pipe(
                retry(1),
                catchError((error) => this.handleError(error)),
                delay(700)
            );
    }

    handleError(error: any) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            errorMessage = error.error.message;
        } else {
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        this.alerts.open(
            errorMessage,
            { status: "error" }
        ).subscribe();
        return throwError(() => {
            return errorMessage;
        });
    }
}

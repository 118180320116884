<div class="page" [class.page_hide-menu-main]="isHideMenu">
    <div class="page__navigation" [class.page_hide-menu]="isHideMenu">
        <app-navigation (toggleMenuHiding)="toggleMenuHiding($event)" [routes]="routes"  ></app-navigation>
        <tui-loader [showLoader]="isLoading"></tui-loader>
    </div>
    <main>
        <router-outlet></router-outlet>
    </main>
</div>

<app-header></app-header>